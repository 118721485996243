<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">directory</div>
      </v-row>
      <v-row class="">
        <div class="">
          Find all the information about all members of the assembly. You can
          also search for a specific member to view their details.
        </div>
      </v-row>
    </v-col>
    <div class="pa-2">
      <v-container fluid>
        <v-row justify="end">
          <v-col cols="6">
            <v-text-field
              outlined
              label="Search member"
              prepend-inner-icon="mdi-magnify"
              dense
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              class="black--text elevation-10"
              :headers="headers"
              :items="members"
              :search="search"
            >
              <template v-slot:[`item.pic`]="{ item }">
                <v-avatar size="45">
                  <v-img v-if="item.profile" :src="item.profile" />
                  <v-img
                    v-else
                    src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  />
                </v-avatar>
              </template>
              <template v-slot:[`item.role`]="{ item }">
                {{ item.role.name }}
              </template>
              <template v-slot:[`item.committee_type_id`]="{ item }">
                {{
                  item.committee_type_id == null ? "None" : item.committees.name
                }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-avatar>
                  <v-btn
                    icon
                    color="primary"
                    @click="(selectedMember = item), (dialog = true)"
                    ><v-icon>mdi-eye</v-icon>
                  </v-btn>
                </v-avatar>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- profile dialog -->
    <v-dialog v-model="dialog" v-if="selectedMember" width="auto">
      <v-card width="500" class="mx-auto">
        <v-card-title class="text-uppercase">
          <p class="mx-auto">About {{ selectedMember.name }}</p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
          <div class="d-flex">
            <v-avatar tile size="200">
              <v-img
                v-if="selectedMember.profile"
                :src="selectedMember.profile"
              />
              <v-img
                v-else
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
              />
            </v-avatar>
            <div class="ml-2">
              <h2>{{ selectedMember.name }}</h2>
              <p
                class="text-subtitle-2 black--text"
                v-if="selectedMember.title"
              >
                Majority Leader
              </p>
              <v-divider class="mt-2"></v-divider>
              <v-list two-line dense>
                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon>mdi-circle-small</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Party</v-list-item-title>
                    <v-list-item-subtitle>{{
                      selectedMember.party ? selectedMember.party : "None"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense class="mt-n3">
                  <v-list-item-icon>
                    <v-icon>mdi-circle-small</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Constituency</v-list-item-title>
                    <v-list-item-subtitle>{{
                      selectedMember.constituency
                        ? selectedMember.constituency
                        : "None"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { memberStore } from "../stores/members";

export default {
  name: "Directory",
  data: () => ({
    search: "",
    headers: [
      {
        text: "picture",
        value: "pic",
        class: "text-uppercase black--text",
        width: "100px",
      },
      {
        text: "name",
        value: "name",
        class: "text-uppercase black--text",
      },
      {
        text: "email",
        value: "email",
        class: "text-uppercase black--text",
      },
      {
        text: "committee",
        value: "committee_type_id",
        class: "text-uppercase black--text",
      },
      {
        text: "role",
        value: "role",
        class: "text-uppercase black--text",
      },
      {
        text: "actions",
        value: "actions",
        class: "text-uppercase black--text",
      },
    ],
    dialog: false,
    selectedMember: null,
  }),
  computed: {
    ...mapState(memberStore, ["members"]),
  },
  methods: {
    ...mapActions(memberStore, ["getMembers"]),
  },
  created() {
    this.getMembers();
  },
};
</script>

<style></style>
